/**
 * Travelanium Booking Plugin
 * Version: 1.4.4
 * Author: Travelanium
 * Author URI: http://www.travelanium.com
 * Support: support@travelanium.com
 *
 */

(function($) {
    "use strict";

    var Plugin = {
        name: 'booking',
        version: '1.4.4'
    };

    var methods = {
        options: {
            baseuri:            'https://reservation.travelanium.net/propertyibe2',
            checkInSelector:    '[name="checkin"]',
            checkOutSelector:   '[name="checkout"]',
            adultSelector:      '[name="numofadult"]',
            childSelector:      '[name="numofchild"]',
            roomSelector:       '[name="numofroom"]',
            codeSelector:       '[name="accesscode"]',
            submitSelector:     '[name="search"]',
            propertyId:         '[name="propertyId"]',
            onlineId:           4,
            builtinDatepicker: {
                enabled:            true,
                dateFormat:         'dd M yy',
                altCheckin:         'alt_checkin',
                altCheckout:        'alt_checkout',
            },
            secretCode:         null,
            propertyGroup:      null,
            language:           null,
            currency:           null,
            confl:              null,
            validate:           function(){return true},
            beforeSubmit:       function(){},
            afterSubmit:        function(){},
            debug:              false
        },
        init: function(el, options) {
            var _data = el[Plugin.name] = $.extend({element: el}, this.options, options);
            var _this = el;
            var $this = $(el);
            var checkin, checkout;

            if (!_data.builtinDatepicker.enabled) {
                checkin = $this.find(_data.checkInSelector);
                checkout = $this.find(_data.checkOutSelector);
            } else {
                createBuiltinDatepicker(_this);
                checkin = $this.find('[name="'+_data.builtinDatepicker.altCheckin+'"]');
                checkout = $this.find('[name="'+_data.builtinDatepicker.altCheckout+'"]');
            }

            $this.on('submit', function(event) {
                event.preventDefault();
                
                if (!_data.validate(_this)) {
                    return;
                }
                _data.beforeSubmit(_this);
                reservation(_this, checkin, checkout);
                _data.afterSubmit(_this);
            });

            $this.find(_data.submitSelector).on('click', function(event) {
                event.preventDefault();

                if (!_data.validate(_this)) {
                    return;
                }
                _data.beforeSubmit(_this);
                reservation(_this, checkin, checkout);
                _data.afterSubmit(_this);
            });

            if (_data.debug)
                rundebug(_this, options);
        },
        update: function(prop, val, el) {
            el[Plugin.name][prop] = val;
        },
    };

    /**
     * Helper function to get accesscode
     * @param {String} el 
     */
    function getCode(el) {
        var _this = el;
        var _data = _this[Plugin.name];
        var $this = $(el);

        var code;
        var acode = $this.find(_data.codeSelector).val();
        var scode = _data.secretCode;

        if (acode !== '') {
            code = acode;
        } else if (scode !== null) {
            code = scode;
        }

        code = acode !== '' ? acode : scode !== null ? scode : false;

        return code;
    }

    /**
     * @description Generate reservation link and open new tab
     * @param {String} el 
     * @param {Object} checkin 
     * @param {Object} checkout 
     */
    function reservation(el, checkin, checkout) {
        var _this = el,
            _data = el[Plugin.name],
            $this = $(el);

        var url, pid,
            base = _data.baseuri,
            params = {};

        if ($.isNumeric(_data.propertyId)) {
            pid = _data.propertyId;
        } else if ($this.find(_data.propertyId).length) {
            pid = $this.find(_data.propertyId).val();
        } else {
            console.error('Property ID is not set or invalid format');
        }
        
        addParam(params, 'propertyId', pid);
        addParam(params, 'onlineId', _data.onlineId);
        addParam(params, 'checkin', checkin.val());
        addParam(params, 'checkout', checkout.val());

        if ($this.find(_data.adultSelector).length)
            addParam(params, 'numofadult', $this.find(_data.adultSelector).val());
        
        if ($this.find(_data.childSelector).length)
            addParam(params, 'numofchild', $this.find(_data.childSelector).val());
        
        if ($this.find(_data.roomSelector).length)
            addParam(params, 'numofroom', $this.find(_data.roomSelector).val());

        if (getCode(_this))
            addParam(params, 'accesscode', getCode(_this));

        if (_data.propertyGroup !== null)
            addParam(params, 'pgroup', _data.propertyGroup);

        if (_data.currency !== null)
            addParam(params, 'currency', _data.currency);

        if (_data.confl !== null)
            addParam(params, 'confl', _data.confl);

        if (_data.language !== null)
            addParam(params, 'lang', _data.language);

        var allParams = $.param(params);
        url = base + '?' + allParams;

        if (_data.debug) {
            console.info(url);
            return false;
        }

        window.open( gaTracking(url, _this) );
    }

    /**
     * @description Create Builtin Datepicker with jQuery UI
     * @param {String} el 
     */
    function createBuiltinDatepicker(el) {
        var _data = el[Plugin.name];
        var _this = el;
        var $this = $(el);

        var $checkIn  = $this.find(_data.checkInSelector).attr('readonly', true);
        var $checkOut = $this.find(_data.checkOutSelector).attr('readonly', true);
        var $dpCheckIn = $('<input/>').attr({
            type: 'hidden',
            name: _data.builtinDatepicker.altCheckin,
        });
        var $dpCheckOut = $('<input/>').attr({
            type: 'hidden',
            name: _data.builtinDatepicker.altCheckout,
        });

        $this.append($dpCheckIn);
        $this.append($dpCheckOut);

        var today = new Date();
        var tomorrow = nextday( today, 1 );

        $checkIn.datepicker({
            minDate: today,
            changeMonth: false,
            changeYear: false,
            dateFormat: _data.builtinDatepicker.dateFormat,
            altFormat: 'yy-mm-dd',
            altField: $dpCheckIn,
            numberOfMonths: 1,
            onSelect: function (dateFormat, inst) {
                $checkOut.datepicker('option', 'minDate', nextday(dateFormat, 1));
                setTimeout(function() {
                    $checkOut.datepicker('show');
                }, 350);
            }
        });

        $checkOut.datepicker({
            minDate: tomorrow,
            changeMonth: false,
            changeYear: false,
            dateFormat: _data.builtinDatepicker.dateFormat,
            altFormat: 'yy-mm-dd',
            altField: $dpCheckOut,
            numberOfMonths: 1
        });

        $checkIn.datepicker('setDate', today);
        $checkOut.datepicker('setDate', tomorrow);
    }

    function addParam(obj, name, val) {
        if (typeof obj !== 'object') return false;
        obj[name] = val;
        return true;
    }

    function nextday(from, day) {
        var time = day * 24 * 60 * 60 * 1000;
        var date = new Date( new Date(from).getTime() + time );
        return date;
    }

    /**
     * @description Decorate url with Google Analytics parameters
     * @since 1.4.0
     * @param {String} url 
     */
    function gaTracking(url, el) {
        var output = url;
        try {
            if (!ga) return output;
            ga(function(tracker) {
                if (tracker == undefined)
                    tracker = ga.getAll()[0];
                if (!tracker) return output;
                if (!tracker.get('linkerParam')) return output;
                var linker = new window.gaplugins.Linker(tracker);
                output = linker.decorate(url);
            });
        } catch (e) {
            console.info(e.message);
        }
        return output;
    }

    /**
     * @description Display information in console
     * @param {String} el 
     * @param {Object} opts 
     */
    function rundebug(el, opts) {
        var info = {
            Element: el,
            PluginName: Plugin.name,
            Version: Plugin.version,
            defaults: methods.options,
            configs: opts,
        };
        console.info(info);
    }

    $.fn[Plugin.name] = function(options, prop, val) {
        if (typeof options == 'object' || options == undefined) {
            return this.each(function() {
                if (this[Plugin.name] == undefined)
                    methods.init(this, options);
            });
        } else if (typeof options == 'string' && methods[options]) {
            return this.each(function() {
                methods[options](prop, val, this);
            });
        } else {
            $.error('No '+options+' method of $.fn.booking');
        }
    };
})(jQuery);
